import {getEnv} from 'mobx-state-tree';

export default (self) => {
	const App = getEnv(self).app;

	return {
		//
		//
		// COMMON
		//

		get usersBase() {
			return '/users';
		},

		get endpoint() {
			return `${self.usersBase}/${self._id}`;
		},

		//
		//
		// USERs
		//

		get profileEndpoint() {
			return `${self.usersBase}/me/profile`;
		},

		get informationEndpoint() {
			return `${self.usersBase}/me`;
		},

		get locationEndpoint() {
			return `${self.usersBase}/location`;
		},

		//
		//
		// USER
		//

		get switchOrgEndpoint() {
			return `${self.endpoint}/primary_org`;
		},

		get signatureEndpoint() {
			return `${self.endpoint}/signature`;
		},

		get initialsEndpoint() {
			return `${self.endpoint}/initials`;
		},

		get avatarEndpoint() {
			return `${self.endpoint}/avatar`;
		},

		get notificationsEndpoint() {
			return `${self.endpoint}/notifications`;
		},

		get billingAdminOrgsEndpoint() {
			return `${self.endpoint}/orgs`;
		},

		resendConfirmationEndpoint(id) {
			if (id) {
				return `${self.usersBase}/${id}/confirmation/resend`;
			}

			return `${self.endpoint}/confirmation/resend`;
		},

		//
		//
		// AUTH
		//

		get authBase() {
			return '/auth';
		},

		get loginStatus() {
			return `${self.authBase}/status`;
		},

		get loginExistingToken() {
			return App.getConfig('auth.disableDash')
				? `${self.authBase}?dash=true`
				: `${self.usersBase}/${self._id}/dash`;
		},

		get loginEndpoint() {
			return `${self.authBase}/login`;
		},

		get changePasswordEndpoint() {
			return `${self.authBase}/password`;
		},

		get adminChangePasswordEndpoint() {
			return `${self.authBase}/admin/password`;
		},

		get adminToggleActiveStatusEndPoint() {
			// make sure endpoint is called by user instance but not admin user instance
			return `${self.endpoint}/activate`;
		},

		get adminRevokeUserTokensEndpoint() {
			// make sure endpoint is called by user instance but not admin user instance
			return `${self.authBase}${self.endpoint}/tokens`;
		},

		get updateRoleEndpoint() {
			// make sure endpoint is called by user instance but not admin user instance
			return `${self.endpoint}/roles`;
		},

		requestResetPasswordEndpoint(platform) {
			return `${self.authBase}/reset?app=${platform}`;
		},

		resetPasswordEndpoint(resetId, email, token) {
			return `${self.authBase}/reset/${resetId}/${email}/?token=${token}`;
		},

		// get the api token
		get apiTokenEndpoint() {
			return '/token';
		},

		// get the registration questions
		get registrationQuestions() {
			return `${self.baseAppParameterEndpoint}/registrationQuestions`;
		},

		get userCategoriesEndpoint() {
			return `${self.endpoint}/dg_categories`;
		},

		get acceptTermsAndConditionsEndpoint() {
			return `${self.endpoint}/tc`;
		},

		get searchHistoryList() {
			return `${self.endpoint}/searchHistory`;
		}
	};
};
