import each from 'lodash/each';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import map from 'lodash/map';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'mobx-react';

import {lsSet, lsGet} from '@zegal/components/src/base/common/utils/localstorage';

import App from './base';
import addCoreAjax from './core/ajax';
import addCoreRoute from './core/route';
import addCoreHeaders from './core/ajaxHeaders';
import addCoreLogs from './core/logs';
import addCoreConfig from './core/config';
import addCoreLoading, {addLoadingActions} from './core/loading';
import addErrorActions from './core/error';
import addModalActions from './core/modal';

// console.log('Loading baseapp core!');
// console.log('========================');

addCoreLogs(App);
addCoreAjax(App);
addCoreRoute(App);
addCoreHeaders(App);
addCoreLoading(App);
addCoreConfig(App);

// reload the app
App.restart = function (timeout = 0) {
	setTimeout(function () {
		lsSet('DragonRedirect', {url: window.location.href});
		window.location = '';
	}, timeout);
};

const stagedURL = 'stagedURL';

App.actions = {
	warn(message, _window = window) {
		_window.console.warn(message);
	},

	fetch(url, method = 'GET', data) {
		// console.log('data', data);
		// console.log('method', method);

		const headers = Object.assign({'Content-Type': 'application/json'}, App.getHeaders());

		// const headers = Object.assign({'Content-Type': 'application/json'}, App.getCustomHeaders())
		// console.log('headers', headers);

		const checkStatus = (response) => {
			if (response.ok) {
				return response;
			} else {
				const error = new Error(response.statusText);
				console.error(error);
				//return Promise.reject(error);
			}
		};

		return fetch(App.API + url, {
			method,
			headers,
			body: JSON.stringify(data)
			// credentials: 'include'
		})
			.then(checkStatus)
			.then((result) => result.json());
	},

	getMenus() {
		// console.log('App.menusLoaded', App.menusLoaded);
		return () => (
			<div>
				{map(App.stores.menu.getShowingMenus(), (Menu, idx) => (
					<Menu key={'menu' + idx} />
				))}
			</div>
		);
	},

	getRegion(region = 'main', _win = window) {
		const officialRegion = App.getConfig(`htmlIds.${region}`);

		if (officialRegion) {
			return _win.document.getElementById(officialRegion);
		} else {
			_win.console.warn('Trying to find un-findable region:', region);
			return false;
		}
	},

	/**
	 * Checks for React component existence in App
	 * and unmounts if found - prevents memory leaks
	 *  - calls public React.unmountComponentAtNode(DOM id)
	 *  - should be a parent component so it cleans up the children comp's
	 *
	 */
	unmountReactComponent(region = 'main') {
		if (typeof region === 'string') {
			region = this.getRegion(region);
		}

		const node = ReactDOM.findDOMNode(region);
		ReactDOM.unmountComponentAtNode(node);
	},

	/**
	 * Wrap a page in a store providing wrapper
	 *
	 * @param {String} Component -
	 * @param {String} options -
	 */
	wrapPage(Component, options = {}) {
		// hack to make React available after the bulid
		// because it will look for this var in the react codes
		// that do the jsx transforms

		class Wrapper extends React.Component {
			render() {
				let stores = {};

				// here is the logic to pick which store to use
				// so that we can pass any we like into the provider
				if (options.provide) {
					// console.log('options.provide', options.provide);

					// default all:
					if (isBoolean(options.provide)) {
						options.provide = ['general', 'store', 'user', 'orgs', 'contacts'];
					}

					// add one:
					if (isString(options.provide)) {
						stores[options.provide] = App.stores[options.provide];
					} else {
						// add many:
						each(options.provide, (store) => {
							stores[store] = App.stores[store];
						});
					}
				}

				return (
					<Provider {...stores}>
						<div>
							<Component {...this.props} {...options} />
						</div>
					</Provider>
				);
			}
		}

		return Wrapper;
	},

	resetCache(_app = App) {
		return (_app.cache = {});
	},

	/**
	 * Set the title of the page
	 *
	 * @param titleKey string - i18n key for document title or string
	 */
	changeTitle(titleKey = '', options = {}, _win = window, app = App) {
		let title = titleKey;

		if (isFunction(app.t)) {
			const titleFromScribe = app.t('general.windowTitle');

			if (titleFromScribe) {
				title = titleFromScribe;
			}
		}

		// skip all fancy stuff
		if (options.manual) {
			title = titleKey;
		}

		_win.document.title = title;

		return _win.document.title;
	},

	// sets window.document favicon to namespace icon
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	changeFavicon(namespace = '', _win = window) {
		const favicon = _win.document.getElementById('favicon');
		const faviconChecker = App.stores?.general?.theme?.favicon;
		if (faviconChecker === '' && namespace === 'zegal') {
			favicon.href = 'myfavicon.ico';
		} else {
			favicon.href = faviconChecker;
		}
		return App.actions;
	},

	getstagedURL() {
		return lsGet(stagedURL);
	},

	captureUrl(forceSet = false, _window = window) {
		const blackList = ['/', 'switch-org', 'login', '/activities'];

		const location = _window.location;
		const _stagedURL = App.actions.getstagedURL();
		if (location.href !== _stagedURL && !forceSet && !blackList.includes(location.pathname)) {
			lsSet(stagedURL, {
				url: location.href,
				path: location.pathname
			});

			return App.actions.getstagedURL();
		}
	},

	clearCapturedUrl() {
		return lsSet(stagedURL, {});
	},

	loadStagedUrl(history) {
		const staged = App.actions.getstagedURL();
		App.actions.clearCapturedUrl();
		if (staged && staged.url) {
			history.push(staged.path);
		}
	},

	updateTheme(namespace = 'zegal', app = App) {
		const currentNamespace = get(lsGet('namespace'), 'title');
		if (namespace !== currentNamespace) {
			app.changeTheme(app, namespace);
			window.location.reload();
		}
		return App.actions;
	}
};

// console.log('Setting up baseApp actions')
addErrorActions(App);
addLoadingActions(App);
addModalActions(App);

App.cache = {};

export default App;
