import {flow} from 'mobx-state-tree';

export const checkAvailableEndPoint = (email) => {
	return `/users/check-available?email=${encodeURIComponent(email)}`;
};

export default (self) => {
	return {
		checkAvailable: flow(function* (email) {
			const userAvailable = yield self.serverCall(checkAvailableEndPoint(email), {}, 'GET');
			return userAvailable.available && !userAvailable?.user;
		}),

		getByUserEmail(email) {
			return self.serverCall(self.getUserByEmailEndPoint(email), {}, 'GET');
		},

		getByTerm(term) {
			return self.serverCall(self.searchByTermEndPoint(term), {}, 'GET');
		},

		getByRole(role) {
			return self.serverCall(self.getByRolesEndPoint(role), {}, 'GET');
		}
	};
};
